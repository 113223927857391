<!--
 * @Description: 商城管理
 * @Autor: WangYuan
 * @Date: 2021-06-10 15:46:39
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-04-25 16:26:18
-->
<template>
  <div class="manage">
    <!-- 头部菜单 -->
    <div class="manage-head">
      <div class="manage-head-content" style="justify-content: space-between">
        <git-control />
        <div style="flex:1">
          <el-button size="small f-white bg-theme" @click="create">创建应用</el-button>
          <el-button size="small" @click="onLogout">退出</el-button>
        </div>
      </div>
    </div>

    <!-- 内容 -->
    <div v-for="(type, index) in list" :key="index" class="manage-body">
      <h2 class="manage-body-title">
        {{ type.title }}
      </h2>

      <ul v-if="type.list.length" class="list">
        <li class="list-item mb20" v-for="item in type.list" :key="item.id">
          <div class="list-item-con">
            <img class="w50 mt10 radius" src="../assets/image/shopLogo.png" />
            <div class="list-item-con-right" style="flex:1;">
              <div style="display: flex;">
                <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                  <el-button class="f16 shopName"> {{ item.name }}</el-button>
                </el-tooltip>
              </div>

              <div class="f12 remark mt15">
                <span class="isDefault" v-if="item.isDefault == 1">系统默认</span>
                <span class="isDefault" v-else style="background:#fff;height:22px;width:60px;"></span>
              </div>

              <el-tooltip class="item" effect="dark" :content="item.remark" placement="top">
                <el-button class="f12 remark mt15" style="border:none;padding:0px;">
                  {{ item.remark ? item.remark : '---' }}
                </el-button>
              </el-tooltip>
            </div>
          </div>

          <div class="list-item-btn">
            <span class="col1" @click="goApp(item)" style="cursor: pointer">进入应用</span>
            <span class="col2" style="cursor: pointer" @click="toSlotManage(item)">槽位管理</span>

            <div class="col3">
              <el-dropdown>
                <span class="el-dropdown-link" style="color:#333;">更多
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="update(item)">修改</el-dropdown-item>
                  <el-dropdown-item @click.native="del(item.aid)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>

        </li>
      </ul>

      <!-- 空列表 -->
      <el-empty v-else class="mt80">
        <template slot="description">
          <span class="f13 f-grey">{{ `快去创建${type.title}吧` }}</span>
        </template>
      </el-empty>
    </div>
    <div v-if="list.length == 0" class="manage-loading" v-loading="loading"></div>

    <!-- 页尾 -->
    <div class="footer"></div>

    <create-dialog ref="create" :authType="authType" :loginMode="loginMode" @success="getMallList"></create-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getProjectList, delProject, getSceneListByType, getDicts } from "@/api/project";
import { mallTypeList } from "@/config/mall";
import CreateDialog from "@/components/CreateDialog";
import { checkToken } from '@/api/user';
import { getDataBy } from "@/api/form";
import { getTenantComponentGroupList, shopDel } from "../api/easyApp";

export default {
  name: "mall-managet",

  components: {
    CreateDialog,
  },
  data() {
    return {
      show: false,
      loading: false,
      form: {},
      list: [],
      // 刷新token锁
      refreshLock: false,
      // 刷新token的时间
      refreshTime: "",
      authType: '',//租户的授权方式 （常规登录或常规静默登录）silent 静默登录，ticket常规登录，ticket-silent常规静默登录
      loginMode: [],//登录方式  uname_pword 账户密码登录；phone 手机号登录；wxphone微信一键登录
      comList: this.$initializing,
      groupList: [],
      clist: [],
      filePath: '',//图片的前缀
    };
  },
  async created() {
    let params = {
      type: 'cdnAndHttp'
    }
    const cRes = await getDicts(params);
    this.filePath = cRes.data[0].value;

    //获取cdn路径
    let dictType = "scene_classify";//场景分类字典名
    //获取场景分类和场景分类的场景
    const result = await getSceneListByType(dictType);
    this.clist = result.data;

    for (const i in this.clist) {
      //this.clist[i].scene=[];
      if (this.clist[i].sceneEntityList) {
        let filteredArr = this.clist[i].sceneEntityList.map(item => item.sceneId).filter((item, index, array) => array.indexOf(item) === index);
        this.clist[i].scene = filteredArr;
      }
      else {
        this.clist[i].scene = [];
      }
    }

    this.dropProject();
    this.getMallList();
    //检查token
    this.refreshToken();
    //根据租户获取授权方式
    let data = {
      ckey: "auth_type,login_mode"
    }

    let res = await getDataBy(data);
    this.authType = res.data.auth_type.cvalue;
    console.log("==this.authType==:", this.authType);

    if (res.data.login_mode.cvalue) {
      this.loginMode = res.data.login_mode.cvalue.split(',');
    }


  },
  computed: {
    ...mapGetters(["project", "userInfo"]),
  },

  watch: {
    show: {
      immediate: true,
      handler() {
        if (!this.show) this.form = {};
      },
    },
  },

  methods: {
    ...mapMutations(["dropProject", "setProject", "logout"]),

    refreshToken() {
      this.refreshTime = setInterval(() => {
        checkToken(this.refreshTime, this.refreshLock);
      }, 60000);
    },

    //跳转到槽位管理
    async toSlotManage(item) {
      let res = await getTenantComponentGroupList({ aid: this.project.aid });
      this.groupList = res.data;
      let appComList = [];
      for (const i in this.groupList) {
        this.groupList[i]['expanded'] = true;
        for (let j in this.groupList[i].componentList) {
          let componentSign = this.groupList[i].componentList[j].componentSign;
          appComList.push(this.groupList[i].componentList[j]);

          for (let h in this.comList) {
            if (h == componentSign) {
              this.groupList[i].componentList[j]['component'] = this.comList[h];
            }
          }
        }
      }

      item['groupList'] = this.groupList;
      item['appComList'] = appComList;
      this.setProject(item);
      this.$router.push({ name: "slotmanage", params: { aid: item.aid } });
    },

    // 获取商城列表
    async getMallList() {
      let map = new Map();
      let temp = mallTypeList;
      mallTypeList.map((item) => {
        item.list = [];
        map.set(item.type, item.list);
      });

      this.loading = true;
      let { data } = await getProjectList({ userId: this.userInfo.userId });
      data.map((item) => {
        let value = map.get(item.type);
        value.push(item);
      });

      this.list = temp;
      this.loading = false;
    },

    // 创建商城
    create() {
      this.$refs["create"].open();
    },

    // 编辑商城
    async goApp(project) {
      console.log("===编辑商城==");
      //保存下组件
      let data = {
        aid: project.aid
      }
      let res = await getTenantComponentGroupList(data);
      this.groupList = res.data;

      let appComList = [];

      for (const i in this.groupList) {
        this.groupList[i]['expanded'] = true;

        for (let j in this.groupList[i].componentList) {

          let componentSign = this.groupList[i].componentList[j].componentSign;
          appComList.push(this.groupList[i].componentList[j]);

          for (let h in this.comList) {
            if (h == componentSign) {
              this.groupList[i].componentList[j]['component'] = this.comList[h];
            }
          }

        }

      }

      project['groupList'] = this.groupList;
      project['appComList'] = appComList;
      project['filePath'] = this.filePath;

      console.log("==appComList==:", appComList);

      //获取应用的场景
      let sceneType = '';
      for (const i in this.clist) {
        let industry = project.industry.split(',');
        const intersection = this.clist[i].scene.filter(value => industry.includes(value))
        if (intersection.length > 0) {
          if (!sceneType) {
            sceneType = this.clist[i].sceneType;
          }
          else {
            sceneType = sceneType + ',' + this.clist[i].sceneType;
          }
        }
      }

      project['sceneType'] = sceneType;

      this.setProject(project);

      this.$nextTick(() => {
        this.$router.push({ name: "mall" });
      });

    },

    // 编辑商城
    update(project) {
      console.log("===编辑商城==");
      this.$refs["create"].open(project);
    },

    // 删除商城
    del(id) {
      this.$confirm("删除商城后将不可恢复, 是否继续?", "删除商城", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {

        shopDel([id]).then(() => {
          this.getMallList();
        });
      });
    },

    // 退出
    onLogout() {
      this.$confirm("是否确定退出平台?", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.logout();
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button:focus,
.el-button:hover {
  background: #fff !important;
}

.manage {
  position: relative;

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
  }

  .manage-head {
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 10;

    .manage-head-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 970px;
      height: 100%;
      margin: 0 auto;
      line-height: 50px;
      text-align: right;
    }
  }

  .manage-body {
    width: 1000px;
    margin: 100px auto;

    .manage-body-title {
      font-size: 20px;
      color: #000000;
      margin-bottom: 20px;
      padding: 0 15px;
      font-weight: 700;

      span {
        color: #ced1d6;
      }
    }

    .manage-body-title::before {
      content: "";
      color: #409fee;
      margin-right: 6px;
      border-left: 6px solid;
      border-radius: 3px;
    }


    .list {
      display: flex;
      flex-wrap: wrap;

      .list-item {
        width: 23%;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 6px 0px rgba(52, 44, 44, 0.1);
        margin-right: 26px;
        padding-top: 20px;

        .list-item-content {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 220px;
          margin: 10px 15px 50px;
          padding-top: 20px;
          border-radius: 10px;
          background-image: linear-gradient(-50deg, #edf1f7, #ebedf4);
          box-shadow: 0 5px 10px 0 rgba(2, 31, 65, 0.05);
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            box-shadow: 0 20px 40px 0 rgba(15, 48, 85, 0.1);
            margin-top: 5px;
          }

          .list-item-content-type {
            position: relative;
            margin-top: 15px;
            font-size: 14px;
            color: #61616a;
            line-height: 24px;

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              right: -50px;
              width: 35px;
              height: 1px;
              background-color: #e1e0e6;
            }

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: -50px;
              width: 35px;
              height: 1px;
              background-color: #e1e0e6;
            }
          }

          .list-item-content-btn {
            margin: 50px auto 25px;
            width: 180px;
            height: 40px;
            line-height: 40px;
            border-radius: 100px;
            background-color: hsla(0, 0%, 100%, 0.4);

            span {
              display: inline-block;
              width: 50%;
              height: 100%;
              font-size: 12px;
              color: #61616a;
              text-align: center;
              cursor: pointer;
            }
          }
        }

      }
    }
  }


  .list .list-item:nth-child(4n) {
    margin-right: 0px;
  }

  .manage-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .footer {
    height: 200px;
    width: 100%;
  }
}


.list-item-con {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 20px 20px 20px;
}

.isDefault {
  border-radius: 4px;
  background-color: rgba(64, 158, 255, 0.1);
  color: rgba(64, 158, 255, 1);
  font-size: 12px;
  padding: 5px;
  display: inline-block;
}

.remark {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
  width: calc(100% - 70px);
}

.list-item-con-right {
  flex: 1;
  margin-left: 10px;
  width: 25%;
}


.col1,
.col2 {
  width: 33%;
  text-align: center;
  font-size: 12px;
  color: #333;
  border-right: 1px solid #f5f5f5;
}

.col3 {
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: #333;
}

.list-item-btn {
  display: flex;
  align-items: center;
  height: 43px;
  line-height: 43px;
}

.shopName {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
  border: none;
  background: none;
  padding: 0px;
}
</style>
